


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component({
  name: 'form-input-text',
})
export default class FormInputText extends Vue {

  @Prop({ type: String })
  public readonly label: string | TranslateResult;

  @Prop({ type: String })
  public readonly value: string;

  @Prop({ type: String })
  public readonly readonly: string;

  @Prop({ type: String })
  public readonly placeholder: string;

  @Prop({ type: Boolean, default: false })
  public readonly showCopy: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly disabled: boolean;

  public updateValue(value: string): void {
    this.$emit('input', value);
  }

  public onCopyClick(): void {
    if (!this.$refs.input || !this.$refs.buttonCopy) {
      return;
    }

    const input = this.$refs.input as HTMLInputElement;
    const button = this.$refs.buttonCopy as HTMLButtonElement;
    input.select();
    document.execCommand('copy');
    input.setSelectionRange(0, 0);
    input.blur();
    button.innerText = this.$t('controls.form-input-text.copied') as string;
    setTimeout(() => {
      button.innerText = this.$t('controls.form-input-text.copy') as string;
    }, 1000);
  }
}
