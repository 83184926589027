


import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TConferenceRoom } from '@/_modules/promo/types/conference-room.type';
import { TEvent } from '@/_types/event.type';
import { Moment } from 'moment';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import HorizontalMenu from '@/_modules/controls/components/horizontal-menu/horizontal-menu.vue';
import HorizontalMenuItem from '@/_modules/controls/components/horizontal-menu-item/horizontal-menu-item.vue';
import HorizontalMenuArrowLeft
  from '@/_modules/controls/components/horizontal-menu-arrow-left/horizontal-menu-arrow-left.vue';
import HorizontalMenuArrowRight
  from '@/_modules/controls/components/horizontal-menu-arrow-right/horizontal-menu-arrow-right.vue';
import CabinetProgramConferenceRoomDialog
  from '@/_modules/promo-cabinet/components/cabinet-program-conference-room-dialog/cabinet-program-conference-room-dialog.vue';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import DeletePopupContent from '@/_modules/controls/components/delete-popup-content/delete-popup-content.vue';
import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import PromoProgramListItem
  from '@/_modules/promo-program/components/promo-program-list-item/promo-program-list-item.vue';
import HorizontalMenuItemLink
  from '@/_modules/controls/components/horizontal-menu-item-link/horizontal-menu-item-link.vue';
import HorizontalMenuArrowLeftLink
  from '@/_modules/controls/components/horizontal-menu-arrow-left-link/horizontal-menu-arrow-left-link.vue';
import HorizontalMenuArrowRightLink
  from '@/_modules/controls/components/horizontal-menu-arrow-right-link/horizontal-menu-arrow-right-link.vue';
import { Location } from 'vue-router';

type TProgramRoom = {
  room: TConferenceRoom;
  programs: TConferenceProgram[];
};

type TProgramByDate = {
  date: Moment;
  dateText: string;
  dateParam: string;
  rooms: TProgramRoom[];
};

@Component({
  name: 'cabinet-program-list',
  components: {
    SimplePopup,
    DeletePopupContent,
    HorizontalMenu,
    HorizontalMenuItem,
    HorizontalMenuArrowLeft,
    HorizontalMenuArrowRight,
    CabinetProgramConferenceRoomDialog,
    IconSquareEdit,
    IconSquareDelete,
    PromoProgramListItem,
    HorizontalMenuItemLink,
    HorizontalMenuArrowLeftLink,
    HorizontalMenuArrowRightLink,
  },
  computed: {
    ...mapGetters({
      isProgramLoading: 'promoProgramStore/isLoading',
      conferenceRooms: 'promoProgramStore/conferenceRooms',
      lastError: 'promoProgramStore/lastError',
      event: '_eventStore/event',
    }),
  }
})
export default class CabinetProgramList extends Vue {

  public readonly isProgramLoading: boolean;
  public readonly conferenceRooms: TConferenceRoom[];
  public readonly lastError: Error;
  public readonly event: TEvent;

  public programByDates: TProgramByDate[] = [];
  public visibleProgramByDates: TProgramByDate[] = [];
  public currentDateMoment: Moment;
  public selectedDate: Moment = null;
  public selectedProgramByDate: TProgramByDate = null;
  public isCreateConferenceRoomDialogVisible: boolean = false;
  public isEditConferenceRoomDialogVisible: boolean = false;
  public isRemoveProgramConfirmationDialogVisible: boolean = false;
  public programForRemove: TConferenceProgram = null;
  public isRemoveConferenceRoomConfirmationDialogVisible: boolean = false;
  public conferenceRoomForRemove: TConferenceRoom = null;
  public selectedDateParam: string = null;
  public conferenceRoomForEdit: TConferenceRoom = null;

  public firstVisibleConferenceRoomIndex: number = 0;
  public visibleConferenceRooms: TProgramRoom[] = [];

  public created(): void {
    this.currentDateMoment = this.$moment();
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get date(): string {
    return this.$route.params.date || null;
  }

  public get selectedProgramByDateIndex(): number {
    return this.programByDates.indexOf(this.selectedProgramByDate);
  }

  public get isDatesMenuArrowLeftVisible(): boolean {
    return this.programByDates.length > 3;
  }

  public get isDatesMenuArrowLeftDisabled(): boolean {
    return this.selectedProgramByDateIndex < 1;
  }

  public get isDatesMenuArrowRightVisible(): boolean {
    return this.programByDates.length > 3;
  }

  public get isDatesMenuArrowRightDisabled(): boolean {
    return this.selectedProgramByDateIndex >= this.programByDates.length - 1;
  }

  public get isConferenceRoomsMenuArrowLeftVisible(): boolean {
    return this.conferenceRooms.length > 3;
  }

  public get isConferenceRoomsMenuArrowLeftDisabled(): boolean {
    return this.firstVisibleConferenceRoomIndex < 1;
  }

  public get isConferenceRoomsMenuArrowRightVisible(): boolean {
    return this.conferenceRooms.length > 3;
  }

  public get isConferenceRoomsMenuArrowRightDisabled(): boolean {
    return this.firstVisibleConferenceRoomIndex >= this.conferenceRooms.length - 3;
  }

  public get datesMenuArrowLeftTo(): Location {
    const currentIndex = this.selectedProgramByDateIndex;
    if (!this.programByDates || !this.programByDates.length || currentIndex < 0) {
      return {
        name: 'promo-page-cabinet-program',
      };
    }
    const nextIndex = currentIndex - 1;
    return (nextIndex > -1 && nextIndex < this.programByDates.length ) ? {
      name: 'promo-page-cabinet-program-date',
      params: {
        date: this.programByDates[nextIndex].dateParam,
      }
    } : {
      name: 'promo-page-cabinet-program-date',
      params: {
        date: this.programByDates[currentIndex].dateParam,
      }
    };
  }

  public get datesMenuArrowRightTo(): Location {
    const currentIndex = this.selectedProgramByDateIndex;
    if (!this.programByDates || !this.programByDates.length || currentIndex < 0) {
      return {
        name: 'promo-page-cabinet-program',
      };
    }
    const nextIndex = currentIndex + 1;
    return (nextIndex > -1 && nextIndex < this.programByDates.length) ? {
      name: 'promo-page-cabinet-program-date',
      params: {
        date: this.programByDates[nextIndex].dateParam,
      }
    } : {
      name: 'promo-page-cabinet-program-date',
      params: {
        date: this.programByDates[currentIndex].dateParam,
      }
    };
  }

  public onCreateNewConferenceRoomClick(): void {
    this.isCreateConferenceRoomDialogVisible = true;
  }

  public onDatesMenuItemClick(programByDate: TProgramByDate): void {
    this.selectProgramByDate(programByDate);
  }

  public onDatesMenuArrowLeftClick(): void {
    const selectedProgramByDateIndex = this.selectedProgramByDateIndex;
    if (selectedProgramByDateIndex < 1) {
      return;
    }
    this.selectProgramByDate(this.programByDates[selectedProgramByDateIndex - 1]);
  }

  public onDatesMenuArrowRightClick(): void {
    const selectedProgramByDateIndex = this.selectedProgramByDateIndex;
    if (selectedProgramByDateIndex >= this.programByDates.length - 1) {
      return;
    }
    this.selectProgramByDate(this.programByDates[selectedProgramByDateIndex + 1]);
  }

  public onConferenceRoomsMenuArrowLeftClick(): void {
    if (this.firstVisibleConferenceRoomIndex < 1) {
      return;
    }
    this.firstVisibleConferenceRoomIndex--;
    this.setVisibleConferenceRooms();
  }

  public onConferenceRoomsMenuArrowRightClick(): void {
    if (this.firstVisibleConferenceRoomIndex >= this.conferenceRooms.length - 3) {
      return;
    }
    this.firstVisibleConferenceRoomIndex++;
    this.setVisibleConferenceRooms();
  }

  public onCreateConferenceRoomDialogClose(): void {
    this.isCreateConferenceRoomDialogVisible = false;
  }

  public onEditConferenceRoomDialogClose(): void {
    this.isEditConferenceRoomDialogVisible = false;
    this.conferenceRoomForEdit = null;
  }

  public onRemoveProgramConfirmationDialogClose(): void {
    this.isRemoveProgramConfirmationDialogVisible = false;
    this.programForRemove = null;
  }

  public onRemoveConferenceRoomConfirmationDialogClose(): void {
    this.isRemoveConferenceRoomConfirmationDialogVisible = false;
    this.conferenceRoomForRemove = null;
  }

  public onConferenceRoomCreateSessionClick(conferenceRoom: TConferenceRoom): void {
    if (this.date) {
      this.$router.push({
        name: 'promo-page-cabinet-program-date-create',
        params: {
          date: this.date,
          conferenceId: '' + conferenceRoom.id,
        }
      });
    } else {
      this.$router.push({
        name: 'promo-page-cabinet-program-create',
        params: {
          date: this.selectedDate.format('YYYY-MM-DD'),
          conferenceId: '' + conferenceRoom.id,
        }
      });
    }
  }

  public onConferenceRoomEditClick(conferenceRoom: TConferenceRoom): void {
    this.conferenceRoomForEdit = conferenceRoom;
    this.isEditConferenceRoomDialogVisible = true;
  }

  public onConferenceRoomDeleteClick(conferenceRoom: TConferenceRoom): void {
    this.conferenceRoomForRemove = conferenceRoom;
    this.isRemoveConferenceRoomConfirmationDialogVisible = true;
  }

  public onConferenceRoomRemoveConfirmClick(): void {
    if (this.conferenceRoomForRemove) {
      // TODO: loading state?
      this.$store.dispatch('promoProgramStore/removeConferenceRoom', {
        eventId: this.eventId,
        conferenceRoomId: this.conferenceRoomForRemove.id,
      });
      this.conferenceRoomForRemove = null;
    }
    this.isRemoveConferenceRoomConfirmationDialogVisible = true;
  }

  public onProgramRemoveClick(program: TConferenceProgram): void {
    this.programForRemove = program;
    this.isRemoveProgramConfirmationDialogVisible = true;
  }

  public onProgramRemoveConfirmClick(): void {
    if (this.programForRemove) {
      // TODO: loading state?
      this.$store.dispatch('promoProgramStore/removeConferenceProgram', {
        eventId: this.eventId,
        conferenceRoomId: this.programForRemove.conference_id,
        programId: this.programForRemove.id,
      });
      this.programForRemove = null;
    }
    this.isRemoveProgramConfirmationDialogVisible = false;
  }

  public onProgramEditClick(program: TConferenceProgram): void {
    if (this.date) {
      this.$router.push({
        name: 'promo-page-cabinet-program-date-edit',
        params: {
          date: this.date,
          programId: '' + program.id,
        }
      });
    } else {
      this.$router.push({
        name: 'promo-page-cabinet-program-edit',
        params: {
          programId: '' + program.id,
        }
      });
    }
  }

  @Watch('event', { immediate: true })
  private onEventChange(): void {
    this.setProgramByDates();
  }

  @Watch('conferenceRooms', { immediate: true })
  private onConferenceRoomsChange(): void {
    this.setProgramByDates();
  }

  @Watch('date', { immediate: true })
  private onDateChange(): void {
    this.setActiveDatesMenuItem();
    this.setVisibleProgramByDates();
    this.setVisibleConferenceRooms();
  }

  private setProgramByDates(): void {
    this.programByDates = [];

    if (!this.conferenceRooms || !this.conferenceRooms.length) {
      return;
    }

    if (!this.event || !this.event.date_start || !this.event.date_end) {
      return;
    }

    /* TODO: We're not working with time zones, why dates are in utc timezone? */
    const dateStartMoment = this.$moment(this.event.date_start);
    const dateEndMoment = this.$moment(this.event.date_end);

    if (dateEndMoment.isBefore(dateStartMoment)) {
      return;
    }

    dateStartMoment.hours(0).minutes(0).seconds(1);
    dateEndMoment.hours(23).minutes(59).seconds(59);
    const dateCounterMoment = dateStartMoment.clone().hours(12).seconds(0);

    do {

      const dateMoment = dateCounterMoment.clone();
      const dateProgram: TProgramByDate = {
        date: dateMoment,
        dateText: dateMoment.format(DateTimeFormat.MONTH_DATE),
        dateParam: dateMoment.format(DateTimeFormat.DATE_TINY),
        rooms: [],
      };
      if (this.conferenceRooms && this.conferenceRooms.length) {
        this.conferenceRooms.forEach((conferenceRoom: TConferenceRoom): void => {

          const conferenceRoomProgram: TProgramRoom = {
            room: conferenceRoom,
            programs: [],
          };

          if (conferenceRoom.programs && conferenceRoom.programs.length) {
            // TODO: optimization: currently iterating through same array many times
            conferenceRoom.programs.forEach((program: TConferenceProgram): void => {
              /* TODO: We're not working with time zones, why dates are in utc timezone? */
              const programDateMoment: Moment = this.$moment(program.date_start);
              programDateMoment.hours(12).minutes(0).seconds(0);
              if (programDateMoment.isSame(dateMoment, 'date')) {
                conferenceRoomProgram.programs.push(program);
              }
            });
          }

          dateProgram.rooms.push(conferenceRoomProgram);
        });
      }
      this.programByDates.push(dateProgram);
      dateCounterMoment.add(1, 'day');

    } while (dateCounterMoment.isSameOrBefore(dateEndMoment, 'date'));

    this.sortProgramByDates();

    this.setActiveDatesMenuItem();
    this.setVisibleProgramByDates();
    this.setVisibleConferenceRooms();
  }

  private sortProgramByDates(): void {
    if (!this.programByDates || !this.programByDates.length) {
      return;
    }

    for (let i = 0; i < this.programByDates.length; i++) {
      if (!this.programByDates[i].rooms || !this.programByDates[i].rooms.length) {
        continue;
      }
      for (let j = 0; j < this.programByDates[i].rooms.length; j++) {
        if (!this.programByDates[i].rooms[j].programs || !this.programByDates[i].rooms[j].programs.length) {
          continue;
        }

        this.programByDates[i].rooms[j].programs.sort((a, b) => {
          if (a.date_start === b.date_start) {
            if (a.date_end === b.date_end) {
              return 0;
            } else if (a.date_end > b.date_end) {
              return 1;
            } else {
              return -1;
            }
          } else if (a.date_start > b.date_start) {
            return 1;
          } else {
            return -1;
          }
        });
      }
    }
  }

  private setActiveDatesMenuItem(): void {
    if (!this.programByDates || !this.programByDates.length) {
      this.selectedDate = null;
      this.selectedProgramByDate = null;
      return;
    }

    if (this.date) {
      const foundProgramByDate = this.programByDates.find(programByDate => {
        return programByDate.dateParam === this.date;
      });
      if (foundProgramByDate) {
        this.selectedProgramByDate = foundProgramByDate;
      } else {
        /* navigation parameter is not found, navigate to parent route */
        this.$router.push({
          name: 'promo-page-cabinet-program',
        });
        return;
      }
    } else {
      /* select today or first */
      const todayProgramByDate = this.programByDates.find(programByDate => {
        return programByDate.date.isSame(this.currentDateMoment, 'date');
      });
      if (todayProgramByDate) {
        this.selectedProgramByDate = todayProgramByDate;
      } else {
        this.selectedProgramByDate = this.programByDates[0];
      }
    }

    this.selectedDate = this.selectedProgramByDate.date.clone();
    this.selectedDateParam = this.selectedDate.format(DateTimeFormat.DATE_TINY);
  }

  private setVisibleProgramByDates(): void {
    // number of visible items is 3 or less

    this.visibleProgramByDates = [];
    if (this.programByDates.length <= 5) {
      this.visibleProgramByDates = [ ...this.programByDates ];
      return;
    }

    const selectedIndex = this.programByDates.indexOf(this.selectedProgramByDate);

    /* edge cases first */
    if (selectedIndex >= this.programByDates.length - 2) {
      this.visibleProgramByDates = this.programByDates.slice(this.programByDates.length - 5);
    } else if (selectedIndex < 2) {
      this.visibleProgramByDates = this.programByDates.slice(0, 5);
    } else {
      this.visibleProgramByDates = this.programByDates.slice(
        selectedIndex - 2,
        selectedIndex + 3,
      );
    }
  }

  private selectProgramByDate(programByDate: TProgramByDate): void {
    this.selectedProgramByDate = programByDate;
    this.selectedDate = this.selectedProgramByDate.date.clone();
    this.setVisibleProgramByDates();
    this.setVisibleConferenceRooms();
  }

  private setVisibleConferenceRooms(): void {
    this.visibleConferenceRooms = [];
    const roomPrograms = (this.selectedProgramByDate && this.selectedProgramByDate.rooms) || [];
    this.firstVisibleConferenceRoomIndex = Math.min(
      roomPrograms.length - 3,
      Math.max(0, this.firstVisibleConferenceRoomIndex)
    );

    if (roomPrograms.length <= 3) {
      this.visibleConferenceRooms = [ ...roomPrograms ];
      return;
    }

    /* edge cases first */
    if (this.firstVisibleConferenceRoomIndex >= roomPrograms.length - 3) {
      this.visibleConferenceRooms = roomPrograms.slice(roomPrograms.length - 3);
    } else if (this.firstVisibleConferenceRoomIndex < 1) {
      this.visibleConferenceRooms = roomPrograms.slice(0, 3);
    } else {
      this.visibleConferenceRooms = roomPrograms.slice(
        this.firstVisibleConferenceRoomIndex,
        this.firstVisibleConferenceRoomIndex + 3,
      );
    }
  }
}
