


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'delete-popup-content',
})
export default class DeletePopupContent extends Vue {

    @Prop({ type: String, default: '' })
    public readonly message: string;

    @Prop({ type: String })
    public readonly cancelButtonTitle: string;

    @Prop({ type: String })
    public readonly submitButtonTitle: string;

    @Prop({ type: String })
    public readonly error: string;

    @Prop({ type: Boolean, default: false })
    public readonly showError: boolean;

    @Prop({ type: Function })
    public readonly cancel: () => void;

    @Prop({ type: Function })
    public readonly confirmDelete: () => void;

}

