


import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TConferenceRoom } from '@/_modules/promo/types/conference-room.type';
import IconPencilGear from '@/_modules/icons/components/icon-pencil-gear.vue';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import CabinetProgramList from '@/_modules/promo-cabinet/components/cabinet-program-list/cabinet-program-list.vue';
import CabinetProgramConferenceRoomDialog
  from '@/_modules/promo-cabinet/components/cabinet-program-conference-room-dialog/cabinet-program-conference-room-dialog.vue';

@Component({
  components: {
    IconPencilGear,
    SimplePopup,
    CabinetProgramList,
    CabinetProgramCreateConferenceRoomDialog: CabinetProgramConferenceRoomDialog,
  },
  computed: {
    ...mapGetters({
      isProgramLoading: 'promoProgramStore/isLoading',
      conferenceRooms: 'promoProgramStore/conferenceRooms',
    }),
  },
})
export default class CabinetProgram extends Vue {

  public readonly isProgramLoading: boolean;
  public readonly conferenceRooms: TConferenceRoom[];

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public isCreateFirstConferenceRoomDialogVisible: boolean = false;

  public get isEmptyConferenceRooms(): boolean {
    return !this.conferenceRooms || !this.conferenceRooms.length;
  }

  public get isConferenceRoomsAlreadyLoaded(): boolean {
    return Array.isArray(this.conferenceRooms);
  }

  public mounted(): void {
    this.markLoadingIfEmpty();
  }

  public markLoadingIfEmpty(): void {
    if (this.isEmptyConferenceRooms && !this.isConferenceRoomsAlreadyLoaded) {
      this.$store.dispatch('promoProgramStore/setIsLoading', true);
    }
  }

  public onCreateFirstConferenceRoomClick(): void {
    this.isCreateFirstConferenceRoomDialogVisible = true;
  }

  public onCreateFirstConferenceRoomClose(): void {
    this.isCreateFirstConferenceRoomDialogVisible = false;
  }

  // @Watch('conferenceRooms', { immediate: true })
  // private onConferenceRoomsChange(): void {
  //   console.log('');
  //   console.log('onConferenceRoomsChange:', this.conferenceRooms);
  //   console.log('');
  // }

  // @Watch('firstConferenceFormData', { immediate: true, deep: true })
  // private onFirstConferenceFormDataChange(): void {
  //   console.log('');
  //   console.log('firstConferenceFormData:', this.firstConferenceFormData);
  //   console.log('');
  // }

}
