








































import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  name: 'icon-pencil-gear',
})
export default class IconPencilGear extends Vue {

}
