


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import FormInputText from '@/_modules/controls/components/form-input-text/form-input-text.vue';
import { TConferenceRoom } from '@/_modules/promo/types/conference-room.type';
import { Validations } from 'vuelidate-property-decorators';
import { TVuelidateRuleSet } from '@/_types/vuelitation-rule-set.type';

@Component({
  name: 'cabinet-program-conference-room-dialog',
  components: {
    FormInputText,
  },
  ...mapGetters({
    lastError: 'promoProgramStore/lastError',
  }),
})
export default class CabinetProgramConferenceRoomDialog extends Vue {

  @Prop({ type: Object })
  public readonly conferenceRoom: TConferenceRoom;

  public readonly lastError: Error;

  @Validations()
  public validations: TVuelidateRuleSet<TConferenceRoom> = {
    formData: {
      title: {
        required,
      }
    }
  }

  public isProcessing: boolean = false;

  public formData: TConferenceRoom = {
    event_id: null,
    title: '',
  };

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public mounted(): void {
    if (this.conferenceRoom) {
      this.formData.event_id = this.eventId;
      this.formData.title = this.conferenceRoom.title || '';
    } else {
      this.formData.event_id = this.eventId;
      this.formData.title = '';
    }
  }

  public async onSubmit(): Promise<void> {
    this.$v.formData.$touch();
    if (this.$v.formData.$invalid) {
      return;
    }

    this.isProcessing = true;
    if (this.conferenceRoom) {
      await this.$store.dispatch('promoProgramStore/updateConferenceRoom', Object.assign({
        id: this.conferenceRoom.id,
      }, this.formData));
    } else {
      await this.$store.dispatch('promoProgramStore/createConferenceRoom', Object.assign({}, this.formData));
    }
    this.isProcessing = false;

    if (!this.lastError) {
      this.$emit('saved');
    } else {
      // TODO: ?
    }
  }

}
